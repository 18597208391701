<template>
  <div>

    <b-container class="bg-light pt-5 pb-5">
      <b-row>
        <b-col md="12">

          <!-- <math-field id="mathfield">x=\frac{-b\pm \sqrt{b^2-4ac}}{2a}</math-field>
          {{ formula }}
          <button @click="consoleformula">view latex</button> -->
          <div class="privacy">
            <h3 class="font-weight-bolder text-center">Privacy Policy for ezygo.app</h3>
            <br />
            <h5>Privacy Policy</h5>
            <p>
              Last updated: 15-03-2023 <br />

              This Privacy Policy describes Our policies and procedures on the collection,
              use and disclosure of Your information when You use the Service and tells
              You about Your privacy rights and how the law protects You. <br />

              We use Your Personal data to provide and improve the Service. By using the
              Service, You agree to the collection and use of information in accordance
              with this Privacy Policy. <br />
            </p>
            <br />
            <h5>Interpretation and Definitions</h5>
            <h6>Interpretation</h6>
            <p>
              The words of which the initial letter is capitalized have meanings defined
              under the following conditions. The following definitions shall have the
              same meaning regardless of whether they appear in singular or in plural.
            </p>
            <h6>Definitions</h6>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <b>Account</b> means a unique account created for You to access our
                Service or parts of our Service.
              </li>
              <li>
                <b>Company </b> (referred to as either "the Company", "We", "Us" or "Our"
                in this Agreement) refers to EZYGO INNOVATION LABS PVT LTD,
                Door No. 4/461, 2nd Floor,
                Suite# 1276, Valamkottil Towers,
                Judgemukku, Thrikkakara PO,
                Kakkanad, Kochi, Kerala,
                INDIA-682021.
              </li>
              <li>
                <b>Cookies </b>are small files that are placed on Your computer, mobile
                device or any other device by a website, containing the details of Your
                browsing history on that website among its many uses.
              </li>
              <li><b>Country</b> refers to: India</li>
              <li>
                <b>Device </b> means any device that can access the Service such as a
                computer, a cellphone or a digital tablet.
              </li>
              <li>
                <b>Personal Data</b> is any information that relates to an identified or
                identifiable individual.
              </li>
              <li><b>Service </b> refers to the Website.</li>
              <li>
                <b>Service Provider </b> means any natural or legal person who processes
                the data on behalf of the Company. It refers to third-party companies or
                individuals employed by the Company to facilitate the Service, to provide
                the Service on behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is used.
              </li>
              <li>
                <b>Third-party Social Media Service</b> refers to any website or any
                social network website
              </li>
              <li>
                <b>Usage Data </b> refers to data collected automatically, either
                generated by the use of the Service or from the Service infrastructure
                itself (for example, the duration of a page visit).
              </li>
              <li>
                <b>Website</b> refers to ezygo.app, accessible from http://ezygo.app
              </li>
              <li>
                <b>You </b> means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such individual is
                accessing or using the Service, as applicable.
              </li>
            </ul>
            <h5>Collecting and Using Your Personal Data</h5>
            <h6>Types of Data Collected</h6>
            <h6>Personal Data</h6>
            <p>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or identify
              You. Personally identifiable information may include, but is not limited to:
            </p>
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Usage Data</li>
            </ul>
            <h6>Usage Data</h6>
            <p>
              Usage Data is collected automatically when using the Service.
              <br />

              Usage Data may include information such as Your Device's Internet Protocol
              address (e.g. IP address), browser type, browser version, the pages of our
              Service that You visit, the time and date of Your visit, the time spent on
              those pages, unique device identifiers and other diagnostic data. When You
              access the Service by or through a mobile device, We may collect certain
              information automatically, including, but not limited to, the type of mobile
              device You use, Your mobile device unique ID, the IP address of Your mobile
              device, Your mobile operating system, the type of mobile Internet browser
              You use, unique device identifiers and other diagnostic data. <br />
              We may also collect information that Your browser sends whenever You visit
              our Service or when You access the Service by or through a mobile device.
            </p>
            <h6>Information from Third-Party Services</h6>
            <p>
              If You decide to register through or otherwise grant us access to a
              Third-Party, We may collect Personal data that is already associated with
              Your Third-Party account, such as Your name, Your email address, Your
              activities or Your contact list associated with that account. <br />
              You may also have the option of sharing additional information with the
              Company through Your Third-Party Service's account. If You choose to provide
              such information and Personal Data, during registration or otherwise, You
              are giving the Company permission to use, share, and store it in a manner
              consistent with this Privacy Policy.
            </p>
            <div class="google mt-3">
              <b>Access, Use, Storage and Sharing of your linked Google Account Data</b>
              <p>
                Google Auth 2.0 access token is used to access only the necessary data
                linked to your Google account so as to manage google calendar events in
                order to allow the user to create Google Meet events. The data collected
                is stored in our secure database hosted on third party cloud servers. The
                data collected are shared with a third party (Business transactions, law
                enforcement and other legal requirements) only in such cases discussed
                later in detail (Refer section: Disclosure of Your Personal Data).
              </p>
            </div>

            <h6>Tracking Technologies and Cookies</h6>
            <p>
              We use Cookies and similar tracking technologies to track the activity on
              Our Service and store certain information. Tracking technologies used are
              beacons, tags, and scripts to collect and track information and to improve
              and analyze Our Service. The technologies We use may include:
            </p>
            <ul>
              <li>
                <b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your
                Device. You can instruct Your browser to refuse all Cookies or to indicate
                when a Cookie is being sent. However, if You do not accept Cookies, You
                may not be able to use some parts of our Service. Unless you have adjusted
                Your browser setting so that it will refuse Cookies, our Service may use
                Cookies.
              </li>
              <li>
                <b>Flash Cookies. </b>Certain features of our Service may use local stored
                objects (or Flash Cookies) to collect and store information about Your
                preferences or Your activity on our Service. Flash Cookies are not managed
                by the same browser settings as those used for Browser Cookies. For more
                information on how You can delete Flash Cookies, please read "Where can I
                change the settings for disabling, or deleting local shared objects?"
                available at
                https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-
                flash.html#main_Where_can_I_change_the_settings_for_disabling
                or_deleting_local_shared _objects_
              </li>
              <li>
                <b>Web Beacons.</b> Certain sections of our Service and our emails may
                contain small electronic files known as web beacons (also referred to as
                clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
                for example, to count users who have visited those pages or opened an
                email and for other related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity). <br />
                Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
                remain on Your personal computer or mobile device when You go offline,
                while Session Cookies are deleted as soon as You close Your web browser.
                Learn more about cookies: What Are Cookies?. We use both Session and
                Persistent Cookies for the purposes set out below:
              </li>
              <li>
                <b>Necessary / Essential Cookies</b>
                Type: Session Cookies
                <br />
                Administered by: Us <br />
                Purpose: These Cookies are essential to provide You with services
                available through the Website and to enable You to use some of its
                features. They help to authenticate users and prevent fraudulent use of
                user accounts. Without these Cookies, the services that You have asked for
                cannot be provided, and We only use these Cookies to provide You with
                those services.
              </li>
              <li>
                <b>Cookies Policy / Notice Acceptance Cookies</b> Type: Persistent Cookies
                <br />
                Administered by: Us <br />
                Purpose: These Cookies identify if users have accepted the use of cookies
                on the Website.
              </li>
              <li>
                <b>Functionality Cookies </b> Type: Persistent Cookies <br />
                Administered by: Us <br />
                Purpose: These Cookies allow us to remember choices You make when You use
                the Website, such as remembering your login details or language
                preference. The purpose of these Cookies is to provide You with a more
                personal experience and to avoid You having to re-enter your preferences
                every time You use the Website.
                <br />
                For more information about the cookies we use and your choices regarding
                cookies, please visit our Cookies Policy or the Cookies section of our
                Privacy Policy.
              </li>
            </ul>
            <h6>Use of Your Personal Data</h6>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li>
                <b>To provide and maintain our Service</b> ,including to monitor the usage
                of our Service.
              </li>
              <li>
                <b>To manage Your Account: </b> to manage Your registration as a user of
                the Service. The Personal Data You provide can give You access to
                different functionalities of the Service that are available to You as a
                registered user.
              </li>
              <li>
                <b>For the performance of a contract:</b> the development, compliance and
                undertaking of the purchase contract for the products, items or services
                You have purchased or of any other contract with Us through the Service.
              </li>
              <li>
                <b>To contact You: </b> To contact You by email, telephone calls, SMS, or
                other equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or informative
                communications related to the functionalities, products or contracted
                services, including the security updates, when necessary or reasonable for
                their implementation.
              </li>
              <li>
                <b>To provide You</b> with news, special offers and general information
                about other goods, services and events which we offer that are similar to
                those that you have already purchased or enquired about unless You have
                opted not to receive such information.
              </li>
              <li>
                <b>To manage Your requests: </b> To attend and manage Your requests to Us.
              </li>
              <li>
                <b>For business transfers: </b>We may use Your information to evaluate or
                conduct a merger, divestiture, restructuring, reorganization, dissolution,
                or other sale or transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar proceeding, in
                which Personal Data held by Us about our Service users is among the assets
                transferred.
              </li>
              <li>
                <b>For other purposes: </b>We may use Your information for other purposes,
                such as data analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and improve our
                Service, products, services, marketing and your experience.
              </li>
              <br />
              <p>We may share Your personal information in the following situations:</p>
              <li>
                <b>With Service Providers: </b> We may share Your personal information
                with Service Providers to monitor and analyze the use of our Service, to
                contact You.
              </li>
              <li>
                <b>For business transfers: </b> We may share or transfer Your personal
                information in connection with, or during negotiations of, any merger,
                sale of Company assets, financing, or acquisition of all or a portion of
                Our business to another company.
              </li>
              <li>
                <b>With Affiliates: </b>We may share Your information with Our affiliates,
                in which case we will require those affiliates to honor this Privacy
                Policy. Affiliates include Our parent company and any other subsidiaries,
                joint venture partners or other companies that We control or that are
                under common control with Us.
              </li>
              <li>
                <b>With business partners:</b>We may share Your information with Our
                business partners to offer You certain products, services or promotions.
              </li>
              <li>
                <b>With other users: </b> when You share personal information or otherwise
                interact in the public areas with other users, such information may be
                viewed by all users and may be publicly distributed outside. If You
                interact with other users or register through a Third-Party Social Media
                Service, Your contacts on the Third-Party Social Media Service may see
                Your name, profile, pictures and description of Your activity. Similarly,
                other users will be able to view descriptions of Your activity,
                communicate with You and view Your profile.
              </li>
              <li>
                <b>With Your consent: </b> We may disclose Your personal information for
                any other purpose with Your consent.
              </li>
            </ul>
            <h6>Retention of Your Personal Data</h6>
            <p>
              The Company will retain Your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain and use Your
              Personal Data to the extent necessary to comply with our legal obligations
              (for example, if we are required to retain your data to comply with
              applicable laws), resolve disputes, and enforce our legal agreements and
              policies. <br />
              The Company will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period of time, except when
              this data is used to strengthen the security or to improve the functionality
              of Our Service, or We are legally obligated to retain this data for longer
              time periods.
            </p>
            <h6>Transfer of Your Personal Data</h6>
            <p>
              Your information, including Personal Data, is processed at the Company's
              operating offices and in any other places where the parties involved in the
              processing are located. It means that this information may be transferred to
              — and maintained on — computers located outside of Your state, province,
              country or other governmental jurisdiction where the data protection laws
              may differ than those from Your jurisdiction. <br />
              Your consent to this Privacy Policy followed by Your submission of such
              information represents Your agreement to that transfer.
              <br />
              The Company will take all steps reasonably necessary to ensure that Your
              data is treated securely and in accordance with this Privacy Policy and no
              transfer of Your Personal Data will take place to an organization or a
              country unless there are adequate controls in place including the security
              of Your data and other personal information.
            </p>
            <h5>Disclosure of Your Personal Data</h5>
            <h6>Business Transactions</h6>
            <p>
              If the Company is involved in a merger, acquisition or asset sale, Your
              Personal Data may be transferred. We will provide notice before Your
              Personal Data is transferred and becomes subject to a different Privacy
              Policy.
            </p>
            <h6>Law enforcement</h6>
            <p>
              Under certain circumstances, the Company may be required to disclose Your
              Personal Data if required to do so by law or in response to valid requests
              by public authorities (e.g. a court or a government agency).
            </p>
            <h6>Other legal requirements</h6>
            <p>
              The Company may disclose Your Personal Data in the good faith belief that
              such action is necessary to:
            </p>
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with the Service
              </li>
              <li>Protect the personal safety of Users of the Service or the public</li>
              <li>Protect against legal liability</li>
            </ul>
            <h6>Security of Your Personal Data</h6>
            <p>
              The security of Your Personal Data is important to Us, but remember that no
              method of transmission over the Internet, or method of electronic storage is
              100% secure. While We strive to use commercially acceptable means to protect
              Your Personal Data, We cannot guarantee its absolute security.
            </p>

            <h6>Links to Other Websites</h6>
            <p>
              Our Service may contain links to other websites that are not operated by Us.
              If You click on a third party link, You will be directed to that third
              party's site. We strongly advise You to review the Privacy Policy of every
              site You visit.
              <br />
              We have no control over and assume no responsibility for the content,
              privacy policies or practices of any third party sites or services.
            </p>
            <h6>Changes to this Privacy Policy</h6>
            <p>
              We may update Our Privacy Policy from time to time. We will notify You of
              any changes by posting the new Privacy Policy on this page.
              <br />
              We will let You know via email and/or a prominent notice on Our Service,
              prior to the change becoming effective and update the "Last updated" date at
              the top of this Privacy Policy. <br />
              You are advised to review this Privacy Policy periodically for any changes.
              Changes to this Privacy Policy are effective when they are posted on this
              page.
            </p>
            <h6>Contact Us</h6>
            <p>
              If you have any questions about this Privacy Policy, You can contact us:
              <br />

              By email: <a href="mailto:support@ezygo.app">support@ezygo.app</a>
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: [],
  components: {
  },
  data() {
    return {
      formula: "",
    };
  },
  methods: {
    // consoleformula() {
    //   const mf = document.getElementById("mathfield");
    //   mf.addEventListener("input", () => this.formula = mf.value);
    //   // mf.executeCommand('speak');
    // },
  },
};
</script>
<style >
.privacy .modal {
  height: 100vh !important;
  overflow-y: scroll !important;
}

.privacy h5,
#privacy___BV_modal_title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}

.privacy p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 25px;
}

.privacy h6 {
  margin-top: 10px;
  font-weight: 600;
  font-size: 18px;
}

.privacy ul li {
  font-size: 16px;
  line-height: 25px;
}
</style>
